import request from '../utils/axiosService'

const URL_GET_ALL = '/department/getall'
const URL_DEPARTMENT_PAGING = '/department/paging'
const URL_ADD_DEPARTMENT = '/department/add'
const URL_UPDATE_DEPARTMENT = '/department/update'

export const getAllDepartment = (param) => {
    return request({
        url: URL_GET_ALL,
        method: 'get',
        params: param
    })
}

export const getDepartmentPaging = (params?: any) => {
    return request({
        url: URL_DEPARTMENT_PAGING,
        method: 'get',
        params,
    })
}

export const addDepartment = (data?: any) => {
    return request({
        url: URL_ADD_DEPARTMENT,
        method: 'post',
        data
    })
}

export const updateDepartment = (data?: any) => {
    return request({
        url: URL_UPDATE_DEPARTMENT,
        method: 'put',
        data: data
    })
}