import request from '../utils/axiosService';

const URL_HOME_PRODUCT_PAGING = 'me/product/paging';
const URL_MY_PRODUCT_SAVED_PAGING = 'me/product/pagingsaved';
const URL_MY_PRODUCT_SAVED = 'me/product/saved';
const URL_MY_PRODUCT = 'me/product';

const URL_PRODUCT_PAGING = '/product/paging';
const URL_PRODUCT_LITE_PAGING = '/product/paging/lite';
const URL_MY_PRODUCT_PAGING = '/me/myproduct/paging';
const URL_PRODUCT = '/product';
const URL_ADD_PRODUCT = '/product/add';
const URL_UPDATE_PRODUCT = '/product/update';
const URL_FILE_UPLOAD = '/file/upload';
const URL_MULTIPLE_FILE_UPLOAD = '/file/upload_multi';
const URL_FILE_REMOVE = '/file/remove';

export const uploadFile = (formData) => {
  return request({
    url: URL_FILE_UPLOAD,
    method: 'post',
    data: formData,
    isFormData: true,
  });
};

export const uploadMultipleFile = (formData) => {
  return request({
    url: URL_MULTIPLE_FILE_UPLOAD,
    method: 'post',
    data: formData,
    isFormData: true,
  });
};

export const removeFile = (params) => {
  return request({
    url: URL_FILE_REMOVE,
    method: 'delete',
    params,
  });
};

export const getHomeProductPaging = (params?: any) => {
  return request({
    url: URL_HOME_PRODUCT_PAGING,
    method: 'get',
    params,
  });
};
export const getMyProductSavedPaging = (params?: any) => {
  return request({
    url: URL_MY_PRODUCT_SAVED_PAGING,
    method: 'get',
    params,
  });
};
export const setProductSaved = (params?: any) => {
  return request({
    url: URL_MY_PRODUCT_SAVED,
    method: 'get',
    params,
  });
};

export const getProductPaging = (params?: any) => {
  return request({
    url: URL_PRODUCT_PAGING,
    method: 'get',
    params,
  });
};

export const getProductLitePaging = (params?: any) => {
  return request({
    url: URL_PRODUCT_LITE_PAGING,
    method: 'get',
    params,
  });
};

export const getMyProductPaging = (params?: any) => {
  return request({
    url: URL_MY_PRODUCT_PAGING,
    method: 'get',
    params,
  });
};

export const getProductDetail = (params?: any) => {
  return request({
    url: URL_PRODUCT + `/${params.id}`,
    method: 'get',
    params,
  });
};
export const getMyProductDetail = (params?: any) => {
  return request({
    url: URL_MY_PRODUCT + `/${params.id}`,
    method: 'get',
    params,
  });
};

export const addProduct = (data?: any) => {
  return request({
    url: URL_ADD_PRODUCT,
    method: 'post',
    data,
  });
};

export const updateProduct = (data?: any) => {
  return request({
    url: URL_UPDATE_PRODUCT,
    method: 'post',
    data,
  });
};
