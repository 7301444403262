import { Carousel, CardProduct, CardMobile } from 'components';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';
import './index.css';
import {
  IProduct,
  IProductFilterField,
  IProductFilterParams,
} from 'interfaces';
import {
  getMyProductSavedPaging, getAllDepartment, setProductSaved, getDistricts, getWards, getCities,
} from 'repositories';
import {
  Input,
  Spin,
  Select,
  Slider,
  SliderSingleProps,
  Button,
  Badge,
  message,
} from 'antd';
import * as Utils from '../../../utils/env';
import type { SearchProps } from 'antd/es/input/Search';
import { debounce } from 'lodash';
import {
  NavigationBundle,
  NavigationContext,
} from 'Contexts/NavigationProvider';
import {
  AcreageCategory,
  DEFAULT_PRODUCT_FILTER,
  PriceCategory,
  TypeProducts,
  DirectionTypes,
  billion,
} from 'helpers/config';
import { IFilterOption } from 'interfaces/ICommon';
import { EPriceCategory } from 'helpers/enums';
import { FilterOutlined } from '@ant-design/icons';
import { log } from 'console';
import { ICity, IDistrict, IWard } from 'interfaces/ICommon';

const { Search } = Input;
const { Option } = Select;

export const ProductsSaved = () => {
  const [searchText, setSearchText] = useState<string>('');
  const [products, setProducts] = useState<IProduct[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isCanLoadmore, setIsCanLoadmore] = useState(true);
  const [isLoadingListLoadMore, setIsLoadingListLoadMore] = useState(true);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const { from, to } = useContext<NavigationBundle>(NavigationContext);
  const [fromStateId, setFromStateId] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const [cities, setCities] = useState<Array<ICity>>([]);
  const [districts, setDistricts] = useState<Array<IDistrict>>([]);
  const [wards, setWards] = useState<Array<IWard>>([]);
  const [filter, setFilter] = useState<IProductFilterParams>(
    DEFAULT_PRODUCT_FILTER
  );
  const [filterParams, setFilterParams] = useState<
    Omit<IProductFilterParams, 'price'> | undefined
  >();
  const [isFiltered, setIsFiltered] = useState<boolean>(false);
  const [departments, setDepartments] = useState<Array<any>>([]);

  useEffect(() => {
    getAllDepartments();
    onGetDistricts(50);
    //onGetWard(1610);
    var objectParam = Utils.getSearchParams();
    setFilterParams(objectParam);
    if (!(from && from.state)) getListProduct();
  }, []);

  useEffect(() => {
    if (from && from.state) {
      let data = localStorage.getItem('data')
        ? JSON.parse(localStorage.getItem('data'))
        : [];
      setPage(from.state?.page);
      setSearchText(from.state.query);
      setTotalCount(parseInt(localStorage.getItem('totalCountProduct')));
      if (data) {
        setProducts([...[], ...data]);
        setFromStateId(from.state?.id);
        setIsLoading(false);
        setIsLoadingListLoadMore(false);
      } else {
        getListProduct();
      }
    } else {
      localStorage.removeItem('data');
    }
  }, [from, to]);

  useEffect(() => {
    if (fromStateId) {
      const item = document.querySelector(`#restore-${fromStateId}`);
      if (item) {
        item.scrollIntoView();
      }
    }

    return () => {
      setFromStateId(null);
    };
  }, [fromStateId]);

  useEffect(() => {
    // getListProduct(null, null, filterParams);
  }, [isFiltered, filterParams]);

  const onScrollLoadmore = (e) => {
    if (e) e.preventDefault();
    let element = e.target as HTMLDivElement;
    let scrollTop = element.scrollTop,
      scrollHeight = element.scrollHeight,
      offsetHeight = element.offsetHeight;
    if (isLoadingListLoadMore || !isCanLoadmore || scrollTop == 0) return;

    if (scrollTop + offsetHeight >= scrollHeight) {
      setIsLoadingListLoadMore(true);
      getListProduct(page + 1, null, filterParams);
      setPage(page + 1);
    }
  };

  const onGetCities = async (countryId) => {
    let params = {
      countryId: countryId,
    };
    const rsp = await getCities(params);
    if (rsp.data.data) {
      var data = rsp.data.data.map(item => {
        item.label = item.name;
        item.value = item.id;
        return item;
      });
      setCities(data);
    }


  }
  const onGetDistricts = async (cityId) => {
    let params = {
      cityId: cityId,
    };
    const rsp = await getDistricts(params);
    if (rsp.data.data) {
      var data = rsp.data.data.map(item => {
        item.label = item.name;
        item.value = item.id;
        return item;
      });
      setDistricts(data);
    }
  }
  const onGetWard = async (districtId) => {
    let params = {
      districtId: districtId,
    };
    const rsp = await getWards(params);
    if (rsp.data.data) {
      var data = rsp.data.data.map(item => {
        item.label = item.name;
        item.value = item.id;
        return item;
      });
      setWards(data);
    }
  }

  const performSearch = (text: string) => {
    setSearchText(text);
    setPage(1);
    getListProduct(1, text);
  };

  const debouncedSetSearch = debounce(setSearchText, 0);
  const debouncedSearch = debounce(performSearch, 0);
  const onSearchTextChange = (value: string) => {
    debouncedSetSearch(value);
    debouncedSearch(value);
  };

  const getListProduct = async (
    pageparam = null,
    query = null,
    filterParam = null
  ) => {
    try {
      let requestParam = {
        page: pageparam ?? page,
        query: query ?? searchText,
      };

      if (!!filterParam) {
        requestParam = { ...requestParam, ...filterParam };
      }

      const rsp = await getMyProductSavedPaging(requestParam);
      if (rsp && !!rsp?.data?.data?.data) {
        let productDatas = rsp?.data?.data?.data;
        setTotalCount(rsp?.data?.data?.totalCount);
        setTotalCountProductToLocalStorage(rsp?.data?.data?.totalCount)
        if (!productDatas.length) {
          setIsCanLoadmore(false);
        }
        else if (products.length < rsp?.data?.data?.totalCount) {
          setIsCanLoadmore(true);
        }
        if (query || (searchText && page == 1)) {
          setProducts([...productDatas] ?? []);
          // setPage((pageparam ?? page) + 1);
        } else {
          if (requestParam.page === 1) {
            setProducts(productDatas ?? []);
          } else {
            setProducts([...products, ...productDatas] ?? []);
          }

        }
        setPage(requestParam.page);
      } else {
        setProducts([]);
      }

      setIsLoadingListLoadMore(false);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const getAllDepartments = async () => {
    try {
      var param = { type: 0 }
      const rsp = await getAllDepartment(param);
      if (rsp && rsp?.data?.data) {
        setDepartments(rsp?.data?.data ?? []);
      } else {
        console.error(rsp?.data?.message ?? 'getAllDepartments');
      }
    } catch (error) { }
  };

  const setDataToLocalStorage = () => {
    localStorage.setItem('data', JSON.stringify(products));
  };
  const setTotalCountProductToLocalStorage = (totalCountProduct) => {
    localStorage.setItem('totalCountProduct', totalCountProduct + "");
  };
  const setProductSave = async (productId) => {
    let requestParam = {
      productId: productId,
    };
    await setProductSaved(requestParam);
    var productSaved = products.find(a => a.id == productId);
    productSaved.isSaved = !productSaved.isSaved;
    setProducts([...products] ?? []);
    if (productSaved.isSaved == true)
      message.success('Lưu thành công');
  }
  const renderCardMobile = (product: IProduct, isMobile) => {
    return (
      <CardProduct
        page={page}
        query={searchText}
        setDataToLocalStorage={setDataToLocalStorage}
        setProductSaved={(productId) => { setProductSave(productId) }}
        product={product}
      />
    );
  };

  const onSearch: SearchProps['onSearch'] = (value, _e, info) => { };

  const pushParam = (param) => {

    Utils.pushParamToUrl(param);
  }

  const onChangeFilter = (field: IProductFilterField, value: any) => {
    if (field === 'price') {
      var itemFound = PriceCategory?.find((_) => _?.value === value);
      itemFound = itemFound == null ? PriceCategory[0] : itemFound;
      console.log('2', itemFound);
      setFilter((pre) => ({
        ...pre,
        [itemFound?.fromKey]: value == null ? null : itemFound?.fromValue,
        [itemFound?.toKey]: value == null ? null : itemFound?.toValue,
        price: value,
      }));
      return;
    }

    if (field === 'acreage') {
      var itemFound1 = AcreageCategory?.find((_) => _?.value === value);
      itemFound1 = itemFound1 == null ? AcreageCategory[0] : itemFound1;
      setFilter((pre) => ({
        ...pre,
        [itemFound1?.fromKey]: value == null ? null : itemFound1?.fromValue,
        [itemFound1?.toKey]: value == null ? null : itemFound1?.toValue,
        [field]: value,
      }));
      return;
    }
    if (field === 'districtId') {
      if (value == null)
        setWards([]);
      else
        onGetWard(value);
    }
    // if (field == 'query') {
    //   // var filter = filterParams as IProductFilterParams;
    //   // filter = filter == null ? {} : filter;
    //   filterParams.query = value;
    //   setFilter(
    //     { ...filterParams }
    //   );
    //   return;
    // }

    console.log('f: ', field, value);

    if (!!field) {
      setFilter((pre) => ({ ...pre, [field]: value }));
    }

    // if (!!item?.fromKey && !!item?.toKey) {
    //   setFilter((pre) => ({
    //     ...pre,
    //     [item?.fromKey]: item?.fromValue,
    //     [item?.toKey]: item?.toValue,
    //   }));
    // } else {
    //   setFilter((pre) => ({ ...pre, [field]: value }));
    // }
  };

  const _renderSearchBar = () => {
    return (
      <div className="main-wrapper__search-bar">
        {/* <Search
          value={searchText}
          placeholder="Tìm kiếm theo tên"
          allowClear
          onSearch={onSearch}
          onChange={(e) => onSearchTextChange(e?.target?.value)}
          style={{ width: '84vw' }}
        /> */}
      </div>
    );
  };

  const isDefaultFilter = useMemo(() => {
    // delete filter.price;
    for (const key in filter) {
      if (filter.hasOwnProperty(key)) {
        // If any property other than 'to_price' has a value, it's not the default filter
        if (key !== 'to_price' && !!filter[key]) {
          return false;
        }
        // If 'to_price' is not equal to the default value, it's not the default filter
        if (key === 'to_price' && filter[key] !== 1000 * billion) {
          return false;
        }
      }
    }
    return false; // All properties match the default filter
  }, [filter]);
  const filterOption = (input: string, option?) =>
    (Utils.removeAccents(option?.label) ?? '').includes(Utils.removeAccents(input));
  const _renderFilter = () => {
    const formatter: NonNullable<SliderSingleProps['tooltip']>['formatter'] = (
      value
    ) => `${value / billion} tỷ`;

    const mappedDepartments: Array<any> = departments?.map((d) => ({
      ...d,
      label: d?.name,
      value: d?.id,
    }));

    return (
      <div className="filter-container">
        <div className='d-flex justify-content-end'>
          <div className="total-count-product-loadmore">{products.length}/{totalCount}</div>
          <div className="filter-button" onClick={() => setExpanded(!expanded)}>
            <Button type="default">
              Lọc theo
              <Badge dot={isFiltered}>
                <FilterOutlined className="filter-icon" />
              </Badge>
            </Button>
          </div>
        </div>
        <div className={`filter-content ${expanded ? 'expanded' : ''}`}>
          <div className="filter-grid">
            <div className="filter-grid__item">
              <Input
                value={filter?.query}
                placeholder="Tìm kiếm thông số nhà"
                onChange={(e) =>
                  onChangeFilter('query', e?.target?.value as any)
                }
              />
            </div>

            <div className="filter-grid__item">
              <Select
                allowClear
                placeholder="Quận/Huyện"
                showSearch
                filterOption={filterOption}
                // value={''}
                options={districts}
                onChange={(e) =>
                  onChangeFilter('districtId', e as any)
                }
              />
            </div>
            <div className="filter-grid__item">
              <Select
                allowClear
                placeholder="Phường/Xã"
                showSearch
                filterOption={filterOption}
                // value={''}
                options={wards}
                onChange={(e) =>
                  onChangeFilter('wardId', e as any)
                }
              />
            </div>
            <div className="filter-grid__item">
              <Select
                allowClear
                placeholder="Phân khúc"
                value={filter?.price}
                // options={PriceCategory}
                // onSelect={(value: any) => onSelectFilter('price', value)}
                onChange={(value) => {
                  console.log('1');
                  onChangeFilter('price', value);
                }}
              >
                {PriceCategory?.map((_, index) => (
                  <Option value={_?.value} key={_?.value}>
                    {_?.label}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="filter-grid__item">
              <Select
                allowClear
                placeholder="Diện tích"
                value={filter?.acreage}
                // options={AcreageCategory}
                onChange={(value) => {
                  onChangeFilter('acreage', value);
                }}
              >
                {AcreageCategory?.map((_, index) => (
                  <Option value={_?.value} key={_?.value}>
                    {_?.label}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="filter-grid__item">
              <Select
                allowClear
                placeholder="Hiện trạng"
                value={filter?.typeProduct}
                options={TypeProducts}
                onChange={(value) => {
                  onChangeFilter('typeProduct', value);
                }}
              />
            </div>
            <div className="filter-grid__item">
              <Select
                allowClear
                placeholder="Hướng"
                value={filter?.directionType}
                options={DirectionTypes}
                onChange={(value) => {
                  onChangeFilter('directionType', value);
                }}
              />
            </div>
            <div className="filter-grid__item">
              <Input
                // allowClear
                placeholder="Số sổ"
                value={filter?.seriNumber}
                onChange={(e) =>
                  onChangeFilter('seriNumber', e?.target?.value as any)
                }
              />
            </div>
            <div className="filter-grid__item">
              <Select
                allowClear
                placeholder="Phòng kinh doanh"
                options={mappedDepartments}
                value={filter?.departmentIds}
                onChange={(value) => {
                  onChangeFilter('departmentIds', value as any);
                }}
              />
            </div>
            <div className="filter-grid__item">
              <p>
                Giá:{' '}
                <span className="filter-text-highlight">
                  {filter?.to_price
                    ? `${filter?.from_price / billion} - ${filter?.to_price / billion
                    }`
                    : '0'}
                </span>{' '}
                tỷ
              </p>
              <Slider
                defaultValue={[0, 1000 * billion]}
                value={[filter?.from_price, filter?.to_price]}
                range
                min={0}
                max={1000 * billion}
                step={billion}
                tooltip={{ formatter }}
                onChange={(values) => {
                  setFilter((pre) => ({
                    ...pre,
                    price: undefined,
                    from_price: values[0],
                    to_price: values[1],
                  }));
                }}
              />
            </div>
          </div>
          <div className="filter-footer">
            <Button
              type="default"
              disabled={isDefaultFilter}
              onClick={() => {
                setFilterParams(undefined);
                setExpanded(false);
                setIsFiltered(false);
                setFilter({});
                getListProduct(1);
                Utils.resetParam();
              }}
            >
              Xóa bộ lọc
            </Button>
            <Button
              type="primary"
              disabled={isDefaultFilter}
              onClick={() => {
                let newFilterParam = { ...filter };
                delete newFilterParam.price;
                delete newFilterParam.acreage;

                setFilterParams(newFilterParam);
                // setFilter(DEFAULT_PRODUCT_FILTER)
                pushParam(newFilterParam);
                setExpanded(false);
                setIsFiltered(true);
                getListProduct(1, null, newFilterParam);
              }}
            >
              Áp dụng
            </Button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="container product-page">
      <div className="">
        <Carousel />
      </div>
      <h2 style={{ margin: '10px', float: 'left', fontWeight: '200' }}>Sản phẩm đã lưu</h2>
      {/* <div className='section'>Danh sách sản phẩm</div> */}
      {_renderSearchBar()}
      {_renderFilter()}
      <Spin spinning={isLoading} tip="Đang tải dữ liệu...">
        <div className="content" onScroll={(el) => onScrollLoadmore(el)}>
          <div className={`row gy-3 no-gutters ${isMobile ? 'gx-0' : 'gx-2'}`}>
            {products && products.length > 0 ? (
              products.map((product, index) => {
                return (
                  <div
                    id={`restore-${product?.id}`}
                    key={`${index}-${product?.name}`}
                    // className={`col-${isMobile ? 'md-12' : 'md-4'}`}
                  >
                    {isMobile ? (
                      renderCardMobile(product, isMobile)
                    ) : (
                      renderCardMobile(product, isMobile)
                      // <Card
                      //   product={product}
                      //   page={page}
                      //   query={searchText}
                      //   setDataToLocalStorage={setDataToLocalStorage}
                      // />
                    )}
                  </div>
                );
              })
            ) : (
              <></>
            )}
          </div>
        </div>
      </Spin>
    </div>
  );
};
