import { IFilterOption } from 'interfaces/ICommon';
import { EAcreageCategory, EStatusEmployee,EStatusDepartment, EPriceCategory, ETypeProduct, EDirectionType, EStatusProduct, ERecProfileStatus } from './enums';
import { IProductFilterParams } from 'interfaces';

export const TypeProducts: Array<IFilterOption<ETypeProduct>> = [
  { label: 'Nhà phố/ nhà mặt tiền', value: ETypeProduct.townHouse },
  { label: 'Nhà trong hẻm', value: ETypeProduct.alleyHouse },
  { label: 'Dãy trọ', value: ETypeProduct.boardingHouse },
  { label: 'Đất', value: ETypeProduct.detachedHouse },
  { label: 'Căn hộ dịch vụ', value: ETypeProduct.apartment },
  { label: 'Biệt thự', value: ETypeProduct.villa },
];

export const StatusProducts: Array<IFilterOption<EStatusProduct>> = [
  { label: 'Ẩn', value: EStatusProduct.DELETE },
  { label: 'Bán mạnh', value: EStatusProduct.SALE },
  { label: 'Chủ bán gấp', value: EStatusProduct.SALE_URGENT },
  { label: 'Đã bán', value: EStatusProduct.SOLD },
  { label: 'Chủ tự bán', value: EStatusProduct.OWER_SOLD },
  { label: 'Dừng bán', value: EStatusProduct.STOP_SALE },
];

export const StatusEmployee: Array<IFilterOption<EStatusEmployee>> = [
  { label: 'Ngưng hoạt động', value: EStatusEmployee.DEACTIVE },
  { label: 'Đang hoạt động', value: EStatusEmployee.ACTIVE },
];

export const StatusCustomer: Array<IFilterOption<EStatusEmployee>> = [
  { label: 'Ngưng hoạt động', value: EStatusEmployee.DEACTIVE },
  { label: 'Đang hoạt động', value: EStatusEmployee.ACTIVE },
];
export const StatusDepartment: Array<IFilterOption<EStatusDepartment>> =[
  { label: 'Ngưng hoạt động', value: EStatusDepartment.DEACTIVE },
  { label: 'Đang hoạt động', value: EStatusDepartment.ACTIVE },
]

export const DirectionTypes: Array<IFilterOption<EDirectionType>> = [
  { label: 'Đông', value: EDirectionType.East },
  { label: 'Nam', value: EDirectionType.South },
  { label: 'Bắc', value: EDirectionType.North },
  { label: 'Tây', value: EDirectionType.West },
  { label: 'Đông Nam', value: EDirectionType.SouthEast },
  { label: 'Tây Nam', value: EDirectionType.SouthWest },
  { label: 'Tây Bắc', value: EDirectionType.NorthWest },
  { label: 'Đông Bắc', value: EDirectionType.NorthEast },
];


export const billion = 1000000000

export const PriceCategory: Array<IFilterOption<EPriceCategory>> = [
  {
    label: 'Dưới 3 tỷ',
    value: EPriceCategory.under3B,
    fromKey: 'from_price',
    fromValue: 0,
    toKey: 'to_price',
    toValue: 3 * billion,
  },
  {
    label: 'Từ 3-6 tỷ',
    value: EPriceCategory.from3B_5B,
    fromKey: 'from_price',
    fromValue: 3 * billion,
    toKey: 'to_price',
    toValue: 6 * billion,
  },
  {
    label: 'Từ 6-10 tỷ',
    value: EPriceCategory.from5B_10B,
    fromKey: 'from_price',
    fromValue: 6 * billion,
    toKey: 'to_price',
    toValue: 10 * billion,
  },
  {
    label: 'Từ 10-20 tỷ',
    value: EPriceCategory.from10B_20B,
    fromKey: 'from_price',
    fromValue: 10 * billion,
    toKey: 'to_price',
    toValue: 20 * billion,
  },
  {
    label: 'Từ 20-30 tỷ',
    value: EPriceCategory.from20B_30B,
    fromKey: 'from_price',
    fromValue: 20 * billion,
    toKey: 'to_price',
    toValue: 30 * billion,
  },
  {
    label: 'Từ 30-50 tỷ',
    value: EPriceCategory.from30B_50B,
    fromKey: 'from_price',
    fromValue: 30 * billion,
    toKey: 'to_price',
    toValue: 50 * billion,
  },
  {
    label: 'Trên 50 tỷ',
    value: EPriceCategory.over50B,
    fromKey: 'from_price',
    fromValue: 50 * billion,
    toKey: 'to_price',
    toValue: 1000 * billion,
  },
];

export const AcreageCategory: Array<IFilterOption<EAcreageCategory>> = [
  {
    label: 'Dưới 50m2',
    value: EAcreageCategory.under50m2,
    fromKey: 'from_acreage',
    fromValue: 0,
    toKey: 'to_acreage',
    toValue: 50,
  },
  {
    label: 'Từ 50-100m2',
    value: EAcreageCategory.from50_100m2,
    fromKey: 'from_acreage',
    fromValue: 50,
    toKey: 'to_acreage',
    toValue: 100,
  },
  {
    label: 'Từ 100-200m2',
    value: EAcreageCategory.from100_200m2,
    fromKey: 'from_acreage',
    fromValue: 100,
    toKey: 'to_acreage',
    toValue: 200,
  },
  {
    label: 'Từ 200-300m2',
    value: EAcreageCategory.from200_300m2,
    fromKey: 'from_acreage',
    fromValue: 200,
    toKey: 'to_acreage',
    toValue: 300,
  },
  {
    label: 'Từ 300-500m2',
    value: EAcreageCategory.from300_500m2,
    fromKey: 'from_acreage',
    fromValue: 300,
    toKey: 'to_acreage',
    toValue: 500,
  },
  {
    label: 'Từ 500-1000m2',
    value: EAcreageCategory.from500_1000m2,
    fromKey: 'from_acreage',
    fromValue: 500,
    toKey: 'to_acreage',
    toValue: 1000,
  },
  {
    label: 'Trên 1000m2',
    value: EAcreageCategory.over1000m2,
    fromKey: 'from_acreage',
    fromValue: 1000,
    toKey: 'to_acreage',
    toValue: 1000000,
  },
];

export const DEFAULT_PRODUCT_FILTER: IProductFilterParams = {

};
export const ListRecProfileStatus: Array<IFilterOption<ERecProfileStatus>> = [
  { label: 'Mới', value: ERecProfileStatus.NEW },
  { label: 'Đã liên hệ', value: ERecProfileStatus.CONTACT },
  { label: 'Đạt phỏng vấn', value: ERecProfileStatus.PASS },
  { label: 'Không đạt phỏng vấn', value: ERecProfileStatus.REJECT },
];

const Admin = "admin";
export const CanAdmin = Admin + ";" + "tl_api.admin";
export const CanProduct_Write = CanAdmin + ";" + "tl_api.product.write";
export const CanProduct_Read = CanProduct_Write + ";" + "tl_api.product.read";
export const CanProductBasic_Write = CanProduct_Write + ";" + "tl_api.productbasic.write";
export const CanProductBasic_Read = CanProduct_Read + ";" + CanProductBasic_Write + ";" + "tl_api.productbasic.read";
export const CanEmployee_Write = CanAdmin + ";" + "tl_api.empbasic.write";
export const CanEmployee_Read = CanEmployee_Write + ";" + "tl_api.emp.read";
export const CanRecProfile_Write = CanAdmin + ";" + "tl_api.recprofile.write";
export const CanRecProfile_Read = CanRecProfile_Write + ";" + "tl_api.recprofile.read";
export const Can_Read = CanEmployee_Write + ";" + "tl_api.emp.read";
export const CanEmployeeBasic_Write = CanEmployee_Write + ";" + "tl_api.emp.write";
export const CanEmployeeBasic_Read = CanEmployee_Read + ";" + CanEmployeeBasic_Write + ";" + "tl_api.empbasic.read";
export const CanSetup_Write = CanAdmin + ";" + "tl_api.setup.write";
export const CanSetup_Read = CanSetup_Write + ";" + "tl_api.setup.read";
export const Roles = {
  Admin: "admin",
  CanAdmin: Admin + "," + "tl_api.admin",
  CanProduct_Write: CanAdmin + "," + "tl_api.product.write",
  CanProduct_Read: CanProduct_Write + "," + "tl_api.product.read",
  CanProductBasic_Write: CanProduct_Write + "," + "tl_api.productbasic.write",
  CanProductBasic_Read: CanProduct_Read + "," + CanProductBasic_Write + "," + "tl_api.productbasic.read",
  CanEmployee_Write: CanAdmin + "," + "tl_api.empbasic.write",
  CanEmployee_Read: CanEmployee_Write + "," + "tl_api.emp.read",
  CanRecProfile_Write: CanAdmin + "," + "tl_api.recprofile.write",
  CanRecProfile_Read: CanRecProfile_Write + "," + "tl_api.recprofile.read",
  Can_Read: CanEmployee_Write + "," + "tl_api.emp.read",
  CanEmployeeBasic_Write: CanEmployee_Write + "," + "tl_api.emp.write",
  CanEmployeeBasic_Read: CanEmployee_Read + "," + CanEmployeeBasic_Write + "," + "tl_api.empbasic.read",
  CanSetup_Write: CanAdmin + "," + "tl_api.setup.write",
  CanSetup_Read: CanSetup_Write + "," + "tl_api.setup.read",
};