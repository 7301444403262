import React, { useRef, useCallback, useState, useEffect } from "react";
// import { Card, CardContent } from "@/components/ui/card";
import { FaShareAlt, FaExclamationTriangle, FaHeart, FaPhone } from "react-icons/fa";
// import "react-responsive-carousel/lib/styles/carousel.min.css";
import { FaDollarSign, FaRulerCombined, FaBed, FaBath, FaBuilding, FaMapSigns, FaHome, FaFileAlt } from "react-icons/fa";
import LightGallery from "lightgallery/react";
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-thumbnail.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-rotate.css";
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";
import rotate from "lightgallery/plugins/rotate";
import hash from "lightgallery/plugins/hash";
// import { Carousel } from "react-responsive-carousel";
// import "react-responsive-carousel/lib/styles/carousel.min.css";
import * as Utils from '../../../utils/env'
import { MdHomeWork, MdOutlineFlood } from "react-icons/md";

import moment from 'moment';
import { EStatusProduct } from 'helpers/enums';
import {
    IProduct,
    IProductFilterField,
    IProductFilterParams,
} from 'interfaces';
import {
    TypeProducts,
    DirectionTypes,
} from 'helpers/config';





// import LightGallery from "react-lightgallery";

const images = [
    "/image1.jpg",
    "/image2.jpg",
    "/image3.jpg",
    "/image4.jpg",
    "/image5.jpg",
];




interface RealEstateCardrdProps {
    product: IProduct;
}
export const RealEstateCard: React.FC<RealEstateCardrdProps> = ({
    product,
}) => {
    const containerRef = useRef(null);
    const galleryRef = useRef(null);
    const [galleryContainer, setGalleryContainer] = useState(null);
    const [lgInstance, setLgInstance] = useState(null);

    // const openGallery = (instance) => {
    //     console.log(instance);
    //     debugger;
    //     product.produtMediaFiles.slice(1).map(item => {
    //         instance.instance.galleryItems.push({
    //             alt: item.name,
    //             src: item.url,
    //             subHtml: "",
    //             thumb: item.url
    //         })
    //         instance.instance.items.push("");
    //     })

    //     // lgInstance.openGallery();
    // };

    const onOpen = (detail) => {
        debugger;
        if (detail) {
            // containerRef.current = detail.instance;
            detail.instance.openGallery();
        }
    };
    const onInit = (instance) => {
        instance.instance.openGallery();
    };
    // useEffect(() => {
    //     if (containerRef.current) {
    //         setLgInstance(containerRef.current.instance);
    //     }
    // }, []);
    const RealEstateFeatures = () => {
        return (
            <div className="p-4 border rounded-lg shadow-sm mt-3">
                <h2 className=" font-bold mb-4">Đặc điểm bất động sản</h2>
                <div className="grid grid-cols-2 gap-4">
                    <div className="flex items-center gap-2">
                        <FaDollarSign className="" />
                        <span>Mức giá: <strong>{Utils.convertMilionToBilion(product.price, true)}</strong></span>
                    </div>
                    {/* <div className="flex items-center gap-2">
                        <FaBuilding className="text-lg" />
                        <span>Số tầng: <strong>2 tầng</strong></span>
                    </div> */}
                    <div className="flex items-center gap-2">
                        <FaRulerCombined className="" />
                        <span>Diện tích: <strong>{product.acreage} m²</strong></span>
                    </div>
                    <div className="flex items-center gap-2">
                        <FaMapSigns className="" />
                        <span>Hướng nhà: <strong>{(DirectionTypes.find(a => a.value == product.directionType)?.label)}</strong></span>
                    </div>
                    <div className="flex items-center gap-2">
                        <MdHomeWork className="" />
                        <span>Hiện trạng: <strong>{product.property}</strong></span>
                    </div>
                    {/* <div className="flex items-center gap-2">
                        <FaBed className="text-lg" />
                        <span>Số phòng ngủ: <strong>4 phòng</strong></span>
                    </div> */}
                    {/* <div className="flex items-center gap-2">
                        <FaHome className="text-lg" />
                        <span>Mặt tiền: <strong>10 m</strong></span>
                    </div> */}
                    {/* <div className="flex items-center gap-2">
                        <FaBath className="text-lg" />
                        <span>Số phòng tắm, vệ sinh: <strong>4 phòng</strong></span>
                    </div> */}
                    <div className="flex items-center gap-2">
                        <FaFileAlt className="" />
                        <span>Pháp lý: <strong>Sổ đỏ/ Sổ hồng</strong></span>
                    </div>
                </div>
            </div>
        );
    };
    return (
        <div className="w-full shadow-lg rounded-2xl overflow-hidden">
            <div className="p-4 bg-white border-b">
                <p className=" text-gray-500">
                    <span className="text-orange-500">Bán</span> / <span className="text-orange-500">{product.address?.cityName}</span> / <span className="text-orange-500">{product.address?.districtName}</span> /
                    <span className="font-bold text-orange-500"> {(TypeProducts.find(a => a.value == product.typeProduct)?.label)}</span>
                </p>
                <h1 className="font-bold mt-1">{product.name}</h1>
            </div>

            {/* Main Image Display */}
            <div className="">

                {/* <div
                    style={{
                        width: '100%',
                        height: '800px',
                    }}
                    ref={containerRef}
                ></div> */}
                {/* <Carousel dynamicHeight={true} showArrows={true} infiniteLoop={true} autoPlay={true} interval={3000}>

                    {product.produtMediaFiles.map((img, index) => (
                        <div onClick={e => onOpen(lgInstance)} key={index}>
                            <img src={img.url} alt={`Slide ${index + 1}`} className="" />
                        </div>
                    ))}
                </Carousel> */}
                {/* <button onClick={openGallery}>Open gallery</button> */}
                <LightGallery plugins={[lgZoom, rotate, lgThumbnail]} speed={500} download={false}
                    // container={containerRef.current}
                    // onInit={onInit}
                    // onInit={openGallery}
                    showCloseIcon={true}
                    // showZoomInOutIcons={true}
                    // showZoomInOutIcons
                    zoomFromOrigin
                    slideShowAutoplay={false}
                    forceSlideShowAutoplay={false}
                   
                    appendSubHtmlTo={'.lg-item'}
                    onRotateLeft={e => e.index}
                    isMobile={() => true}
                    mobileSettings={
                        {
                            // previousSlide: "",
                            showCloseIcon: true,
                        }
                    }
                    // plugins={[rotate, lgZoom]}
                    height="100%"

                    // dynamic={true}
                    // // thumbnail
                    // dynamicEl={product.produtMediaFiles.map(item => {
                    //     return {
                    //         src: item.url,
                    //         responsive: item.url,
                    //         thumb: item.url,
                    //         subHtmlUrl: ""
                    //     }
                    // })}
                >
                    {/* <div style={{ textAlign: "center", maxWidth: "600px", margin: "auto" }}> */}

                    {/* </div> */}
                    {/* <a href="https://picsum.photos/600/400">
                        <img src="https://picsum.photos/100/100" alt="Sample 1" />
                    </a>
                    <a href="https://picsum.photos/600/400?2">
                        <img src="https://picsum.photos/100/100?2" alt="Sample 2" />
                    </a> */}

                    {product.produtMediaFiles.map((item, index) => {
                        return index < 1 && <a
                            hidden={index > 0 ? true : false}
                            data-lg-size="1600-1067"
                            className="gallery-item"
                            data-src={item.url}
                            style={{ width: '100%', height: "345px", display: "inline-block" }}
                        >
                            <img
                                className="w-full h-[345px] object-cover img-responsive"
                                src={item.url}
                            />
                        </a>;
                    })}
                    {product.produtMediaFiles.slice(1).map((item, index) => {
                        return index < 12 && <a
                            key={index + "_thumnail_product_detail"}
                            onClick={e => e.stopPropagation()}
                            // hidden={index >= 6 ? true : false}
                            style={{ display: "inline-block" }}
                            data-lg-size="400-400"
                            className="col-4 col-md-2 thumnail flex gap-2 p-2 overflow-x-auto"
                            data-src={item.url}
                        >
                            <img onClick={e => e.stopPropagation()}
                                alt=""
                                className="w-full h-[100px] object-cover img-responsive"
                                src={item.url}
                            />
                        </a>
                    })}
                    {/* <div className="flex gap-2 p-4 overflow-x-auto"> */}
                    {/* {product.produtMediaFiles.slice(1).map((img, index) => (
                        <a
                            key={index + "_thumnail_product_detail"}
                            onClick={e => e.stopPropagation()}
                            // hidden={index >= 6 ? true : false}
                            style={{ width: 130, display: "inline-block" }}
                            data-lg-size="400-400"
                            className="thumnail flex gap-2 p-2 overflow-x-auto"
                            data-src={img.url}
                        >
                            <img onClick={e => e.stopPropagation()}
                                alt=""
                                className="w-full h-[100px] object-cover img-responsive"
                                src={img.url}
                            />
                        </a>;

                    ))} */}
                    {/* </div> */}
                </LightGallery>
            </div>



            <div className="p-4">
                <div className="grid grid-cols-3 gap-4 text-center text-gray-500">
                    <div>
                        <p className="uppercase">Mức giá</p>
                        <p className="text-black  font-bold"> {product.priceSale ?
                            <span>
                                <span className='' style={{ textDecoration: 'line-through' }}>{Utils.convertMilionToBilion(product.price, true)}</span>
                                <span className=''>{Utils.convertMilionToBilion(product.priceSale, true)}</span>
                            </span>
                            :
                            <span className=''> {Utils.convertMilionToBilion(product.price, true)}</span>
                        }</p>
                        <p>~{Utils.convertMilionToBilion((product.priceSale ?? product.price) / parseFloat(product.acreage), false)} tr/m²</p>
                    </div>
                    <div>
                        <p className="uppercase">Hoa hồng</p>
                        <p className="text-black  font-bold">{
                            (product.fee || product.feePercent)
                                ? <span className=''>
                                    {product.fee ? Utils.convertMilionToBilion(product.fee, true) : product.feePercent + "%"}
                                </span>
                                : <span className=''>{"TL"}</span>
                        }</p>

                    </div>
                    <div>
                        <p className="uppercase">Diện tích</p>
                        <p className="text-black  font-bold">{product.acreage} m²</p>
                        {/* <p>Mặt tiền 10 m</p> */}
                    </div>

                    {/* <div>
                        <p className="uppercase">Phòng ngủ</p>
                        <p className="text-black text-lg font-bold flex items-center justify-center gap-1">
                            <FaBed className="text-blue-500" /> 4 PN
                        </p>
                    </div> */}
                </div>
                <div className="mt-4 text-gray-800">
                    <h2 className=" font-bold">Thông tin mô tả</h2>
                    {product.description}
                    <div className="flex items-center gap-2 mt-2">
                        <FaPhone className="text-green-500" />
                        <span className="bg-gray-200 p-2 rounded-md">{product.employee.phone}</span>
                        {/* <button className="bg-blue-500 text-white px-3 py-1 rounded-md">Hiện số</button> */}
                    </div>
                </div>
                <div className="mt-4 flex justify-end gap-4 text-gray-600 ">
                    <FaShareAlt className="cursor-pointer" />
                    <FaExclamationTriangle className="cursor-pointer" />
                    <FaHeart className="cursor-pointer" />
                </div>
                {RealEstateFeatures()}
            </div>
        </div >
    );
};

