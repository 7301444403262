import React from 'react';
import { Link } from 'react-router-dom';
import { FiHeart, FiPhone, FiCompass } from 'react-icons/fi';
import { MdOutlineHomeWork, MdOutlineFlood } from "react-icons/md";
import { IoLocationOutline, IoHomeOutline } from 'react-icons/io5';
import { LuRectangleHorizontal, LuCircleDollarSign } from 'react-icons/lu';
import './index.css';
import * as Utils from '../../utils/env'
import { locationIcon, feeService } from '../../utils/icon';

import moment from 'moment';
import { EStatusProduct } from 'helpers/enums';
import {
  IProduct,
  IProductFilterField,
  IProductFilterParams,
} from 'interfaces';
import {
  TypeProducts,
  DirectionTypes,
} from 'helpers/config';
interface CardProps {
  product: IProduct;
  onItemClick?: () => void;
  page: number,
  setDataToLocalStorage?: Function
  setProductSaved?: Function
  query?: any,
}
const defaultImage = 'https://techland-test.hn.ss.bfcplatform.vn/master/logo/apple-touch-icon.png'
export const CardProduct: React.FC<CardProps> = ({
  product,
  page,
  query,
  setDataToLocalStorage,
  setProductSaved
}) => {
  const formatPrice = (amount: number) => {
    if (amount >= 1000) {
      return `${(amount / 1000).toFixed(1)} tỷ`;
    }
    return `${amount} triệu`;
  };
  const renderLocation = () => {
    return (
      <div className="d-flex align-items-center" style={{ marginTop: '4px', fontSize: '14px' }}>
        <span className='d-flex align-items-center'> {locationIcon(24, 24)} </span>
        &nbsp;:&nbsp;
        <span className='product-location'>
          <a style={{ color: '#2979ff' }} onClick={e => e.stopPropagation()} target='_blank'
            href={'https://www.google.com/maps/dir/?api=1&destination=' + (product.address?.street + ", " + product.address?.wardName + ", " + product.address?.districtName + ', ' + product.address?.cityName)}>{product.address?.cityName ? product.address?.wardName + ", " + product.address?.districtName + ', ' + product.address?.cityName : 'Hồ Chí Minh, Việt Nam'}
          </a>
        </span>
      </div>
    );
  };
  const _getStatusTag = () => {
    const diffDays = moment().diff(product?.updatedAt, 'days');
    if (diffDays <= 3 && product.status == EStatusProduct.SALE) {
      return 1;
    }
    if (product.status > EStatusProduct.SALE) {
      return 2;
    }
    return null;
  };
  const _renderStatusTag = () => {
    const diffDays = moment().diff(product?.updatedAt, 'days');
    if (diffDays <= 3 && product.status == EStatusProduct.SALE) {
      return <div className="status">Mới</div>;
    }
    if (product.status == EStatusProduct.SALE_URGENT) {
      return <div className="sale_urgent">Bán gấp</div>;
    }
    if (product.status >= EStatusProduct.SOLD && product.status <= EStatusProduct.OTHER_SOLD) {
      return <div className="sold">Đã bán</div>;
    }
    if (product.status == EStatusProduct.STOP_SALE) {
      return <div className="stop_sale">Ngừng bán</div>;
    }
    return null;
  };

  return (
    < Link to={{
      pathname: `/admin/product/${product.id}`
    }}
      state={{ id: product.id, page: page, query: query }}
      style={{ textDecoration: 'none' }}
      onClick={() => setDataToLocalStorage()}
    >
      <div className="card-wrapper-product card-container bg-white rounded-lg shadow-md overflow-hidden">
        {_renderStatusTag()}
        {/* Main Image Section */}
        <div className="relative">
          <div className="grid grid-cols-4 gap-1">
            <div className="col-span-3">
              <img
                src={product.produtMediaFiles[0]?.url == null ? defaultImage : product.produtMediaFiles[0]?.url}
                alt={product.name}
                className="w-full h-[300px] object-cover"
              />
            </div>
            <div className="col-span-1 flex flex-col gap-1">
              {product.produtMediaFiles.slice(1, 4).map((img, index) => (
                <img
                  key={index}
                  src={img.url == null ? defaultImage : img.url}
                  alt={`${product.name}-${index + 1}`}
                  className="w-full h-[98px] object-cover"
                />
              ))}
            </div>
          </div>


        </div>

        {/* Content Section */}
        <div className="p-4">
          {/* Title */}
          <h2 style={{  }} className="card-product-title text-xl font-bold mb-3">{product.name}</h2>

          {/* Price and Area Info */}
          <div className="flex items-center gap-4 mb-3">
            <div className="text-red-600 font-bold text-xl">
              {Utils.convertMilionToBilion(product.price, true)} · {product.acreage}m²
            </div>
            <div className="text-gray-600">
              {Utils.convertMilionToBilion(product.price / parseFloat(product.acreage), false)} tr/m²
            </div>
            {(product.fee || product.feePercent) && (
              <div className="flex items-center gap-2 text-gray-600">
                <LuCircleDollarSign />
                {/* {feeService()} */}
                {
                  (product.fee || product.feePercent)
                    ? <span className=''>
                      {product.fee ? Utils.convertMilionToBilion(product.fee, true) : product.feePercent + "%"}
                    </span>
                    : <span className=''>{"TL"}</span>
                }
                {/* <span>{amenities.bathrooms} 🚽</span> */}
              </div>
            )}
            {product.directionType != null && (
              <div className="flex items-center gap-2 text-gray-600">
                <FiCompass />
                <span>{(DirectionTypes.find(a => a.value == product.directionType)?.label)} </span>
                {/* <span>{amenities.bathrooms} 🚽</span> */}
              </div>
            )}

          </div>

          <div className='flex items-center gap-4 mb-3'>
            {/* Dimensions */}
            {product.width && (
              <div className="flex items-center gap-2 text-gray-600 mb-3">
                <LuRectangleHorizontal size={20} />
                <span>{`${product.width}x${product.height}m`}</span>
              </div>
            )}
            {/* Dimensions */}
            {product.typeProduct != null && (
              <div className="flex items-center gap-2 text-gray-600 mb-3">
                <MdOutlineHomeWork size={20} />
                <span>{(TypeProducts.find(a => a.value == product.typeProduct)?.label)} </span>
              </div>
            )}

          </div>
          {product.property != null && (
            <div className="flex items-center gap-2 text-gray-600 mb-3">
              <MdOutlineFlood size={20} />
              <span>{product.property} </span>
              {/* <span>{amenities.bathrooms} 🚽</span> */}
            </div>
          )}
          {/* Location */}
          <div className="flex items-center gap-2 text-gray-600 mb-3">
            <IoLocationOutline size={20} />
            <a style={{ color: 'unset', textDecoration: 'none' }} onClick={e => e.stopPropagation()} target='_blank'
              href={'https://www.google.com/maps/dir/?api=1&destination=' + (product.address?.street + ", " + product.address?.wardName + ", " + product.address?.districtName + ', ' + product.address?.cityName)}>{product.address?.cityName ? <span>{`${product.address.street}, ${product.address.wardName}, ${product.address.districtName}, ${product.address.cityName}`}</span> : 'Hồ Chí Minh, Việt Nam'}
            </a>

          </div>

          {/* Author Info and Contact */}
          <div className="flex items-center justify-between mt-4">
            <div className="flex items-center gap-2">
              <div className="w-10 h-10 bg-gray-200 rounded-full overflow-hidden">
                <img src={product.employee.photo} alt={product.employee.name} className="w-full h-full object-cover" />
              </div>
              <div>
                <div className="font-semibold">{product.employee.name}</div>
                <div className="text-gray-500 text-sm">{Utils.getDateShort(product.updatedAt, "DD-MM-YYYY HH:mm")}</div>
              </div>
            </div>
            <div className="flex">
              {product.employee.phone && (
                <button className="flex items-center gap-2 bg-teal-600 text-white px-4 py-2 rounded-md mr-3">
                  <FiPhone />
                  <span>{product.employee.phone}</span>
                </button>
              )}
              {/* Save Button */}
              <button
                className={`top-4 right-4 bg-white p-2 rounded-full shadow-md icon-save-product ${product.isSaved ? 'is-saved' : ''}`}
                onClick={(e) => { e.stopPropagation(); e.preventDefault(); setProductSaved(product.id); }}
              >

                <FiHeart

                  size={20}
                  fill={product.isSaved ? 'red' : 'none'}
                  stroke={product.isSaved ? 'red' : 'black'}
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};
