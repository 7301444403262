import { create } from 'zustand';
import { getTicketPaging } from 'repositories'; // Adjust the import based on your structure
import { ITicket, ITicketFilter } from 'interfaces'; // Adjust the import based on your structure

interface TicketState {
    tickets: ITicket[];
    totalCount: number;
    totalCountFull: number;
    isLoading: boolean;
    currentPage: number; // Add currentPage to the state
    error: string | null;
    filter: ITicketFilter;
    fetchTickets: (page: number, filter?: Partial<ITicketFilter>) => Promise<void>;
    loadMoreTickets: () => Promise<void>;
    reloadTickets: () => Promise<void>; // Add a dedicated reload function
    resetTickets: () => void;
    resetPageAndTotalCount: () => void; // Reset function
    setFilter: (filter: Partial<ITicketFilter>) => void;
}

const useTicketStore = create<TicketState>((set, get) => ({
    tickets: [],
    totalCount: 0,
    totalCountFull: 0,
    isLoading: false,
    error: null,
    currentPage: 1, // Initialize currentPage
    filter: {},

    setFilter: (newFilter) => {
        set((state) => ({
            filter: { ...state.filter, ...newFilter }
        }));
    },

    fetchTickets: async (page, newFilter?) => {
        set({ isLoading: true, error: null });
        try {
            const currentFilter = newFilter || get().filter;
            const response = await getTicketPaging({
                page,
                ...currentFilter
            });

            var tickets = response.data.data.data
            tickets?.length > 0 && tickets.forEach(ticket => {
                ticket.employeeId = ticket.employee?.id || null
            })

            set((state) => ({
                tickets: page === 1 ? tickets : [...state.tickets, ...tickets],
                totalCount: response.data.data.totalCount,
                isLoading: false,
                currentPage: page,
                filter: newFilter ? { ...state.filter, ...newFilter } : state.filter
            }));
        } catch (error) {
            set({ error: error.message, isLoading: false });
        }
    },

    loadMoreTickets: async () => {
        // Don't proceed if already loading
        if (get().isLoading) return;

        const { currentPage, totalCount, tickets } = get();

        // Don't load more if we already have all tickets
        if (tickets.length >= totalCount) return;

        const nextPage = currentPage + 1; // Get the next page
        await get().fetchTickets(nextPage); // Fetch tickets for the next page
    },

    reloadTickets: async () => {
        const { filter } = get();
        set({ currentPage: 1 }); // Reset to first page
        await get().fetchTickets(1, filter); // Fetch first page of tickets
    },

    resetTickets: () => set({ tickets: [], totalCount: 0, filter: {} }),
    resetPageAndTotalCount: () => set({ currentPage: 1, totalCount: 0 }), // Reset function
}));

export default useTicketStore;