export enum ETicketStatus {
    NEW = 1,
    PROCESSING = 2,
    COMPLETED = 3
}

export enum ETabItem {
    NONE = 0,
    TICKETS_INTERACTED = 1,
    TICKETS_TAKEGUEST = 2,
    TICKETS_ANOTHER = 3,
}

export enum ETicketType {
    Interact,
    TaskGuest,
    Other
}

export enum EActivityType {
    Media, // Truyền thông
    PostNewFeed, // Đăng tin
    Survey, // Đi khảo sát
    CheckPlanning, // Check quy hoạch
    CustomerConsulting, // Tư vấn khách hàng
    DiscountInteraction, // Tương tác giảm giá
    ShowNew, // Xem mới
    Review, // Xem lại
    Negotiation, // Thương lượng
}