import React, { useEffect, useState } from 'react';
import * as Utils from 'utils/env';
import * as Interfaces from 'interfaces'
import * as lodash from 'lodash'
import './index.css'
import { useLocation, useSearchParams } from 'react-router-dom';
import useTicketStore from 'store/ticketStore';

interface DynamicControlProps {
    type?: any,
    totalCountFull?: number,
    totalCount?: number,
    isLoadingTotalFull?: number,
    DEFAULT_TABS?: any[]
}

export const TabMenu = (props: DynamicControlProps) => {
    const { type, isLoadingTotalFull, DEFAULT_TABS } = props
    const [tab, setTab] = useState(null)
    const [countClickTotal, setCountClickTotal] = useState(0)
    // componentWillMount() {
    //     TFExListStore.registryInstanceTab(this)
    //     if (document['user'].Roles && (document['user'].Roles.includes("admin") || document['user'].Roles.includes("tfex_api.admin"))) {
    //         this.isAdmin = true
    //     }
    // }
    const location = useLocation();
    const { totalCount, totalCountFull, resetPageAndTotalCount, setFilter, fetchTickets } = useTicketStore()

    useEffect(() => {
        onPickUpTab(location.search)
    }, [location.search])


    // const onClickGetTotalCount = () => {
    //     this.setState({ countClickTotal: this.state.countClickTotal + 1 }, () => { TFExListStore.getTotalCountFull() })
    // }

    const getData = (): Interfaces.ETabItem => {
        return tab
    }

    const onPickUpTab = (searchParam: string) => {
        setCountClickTotal(0)

        let tab = Interfaces.ETabItem.NONE as Interfaces.ETabItem
        // let DEFAULT_TABS = toJS(TFExListStore.DEFAULT_TABS) as Interfaces.ITabItem[]

        // let parentType = TFExListStore._getTypeFromURL()

        if (searchParam) {
            // var filter = DEFAULT_TABS.find(it => it.type == type)
            switch (true) {
                case searchParam.includes('isInteracted=true'):
                    tab = DEFAULT_TABS.find(it => it.type == Interfaces.ETabItem.TICKETS_INTERACTED)?.type
                    break;
                case searchParam.includes('isTakeGuest=true'):
                    tab = DEFAULT_TABS.find(it => it.type == Interfaces.ETabItem.TICKETS_TAKEGUEST)?.type
                    break;
                case searchParam.includes('isInteracted=false&isTakeGuest=false'):
                    tab = DEFAULT_TABS.find(it => it.type == Interfaces.ETabItem.TICKETS_ANOTHER)?.type
                    break;
                default:
                    tab = null
                    break;
            }
        }

        setTab(tab)
        // TFExListStore.setTotalCountFull(0)
    }

    const onClickTab = async (filter: Interfaces.ITicketFilter) => {
        setCountClickTotal(0)
        // TFExListStore.resetFilter()
        resetPageAndTotalCount()
        setFilter(filter)
        await fetchTickets(1, filter)

        let stringifyObj = Utils.convertFieldsToString(filter)

        Utils.onPushtoUrl(stringifyObj, Utils.history.location && Utils.history.location.pathname)
        onPickUpTab(Utils.serializeTicketFilter(stringifyObj))
    }

    const _compareURLAndFilter = (searchPath: string, filter: Interfaces.ITicketFilter) => {
        if (searchPath && !!(filter)) {
            let filterQueryFromURL = Utils.onPullObjFromUrl(searchPath)
            let stringifyObj = Utils.serializeTicketFilter(filter)
            let filterQueryFromTab = Utils.onPullObjFromUrl('?' + stringifyObj)

            if (JSON.stringify(filterQueryFromURL) == JSON.stringify(filterQueryFromTab))
                return true
        }

        return false
    }

    const _handleOnCheckExistDot = (type: Interfaces.ETabItem) => {
        let defaultTab = DEFAULT_TABS as Interfaces.ITabItem[]
        if (defaultTab && defaultTab.length > 0) {
            let tabCorresponding = defaultTab.find(it => it.type == type)
            if (tabCorresponding && tabCorresponding.isExistedTicketNotRead) {
                return true
            }
        }
        return false

    }

    const renderLoading = () => {
        return <div className="spinner-tfex">
            <div className="bounce-1"></div>
            <div className="bounce-2"></div>
            <div className="bounce-3"></div>
        </div>
    }

    const renderTabItem = (it: Interfaces.ITabItem, index: number) => {
        let isSelected = !!(tab == it.type)
        let haveDot = _handleOnCheckExistDot(it.type)
        return it && <div
            className={`ticket-tabs__header__tabs__item ${isSelected ? 'active' : ''}`}
            key={`${it.type}-${index}`}
            onClick={() => onClickTab(it.filter)}>
            {it.name}
            {haveDot && <div className="tfex-dot-blue" />}
        </div>
    }

    const renderBody = () => {

        let Clone_DEFAULT_TABS = lodash.cloneDeep(DEFAULT_TABS) as Interfaces.ITabItem[]
        let tabCorresponding = Clone_DEFAULT_TABS
        // ? Clone_DEFAULT_TABS.filter(it => {
        //     if (it.parentType == type && it.parentType == Utils.EPATHTFEX.TicketInCharged) {
        //         if (document['roleTFEXTicketViewSelf'] && it.type == Interfaces.ETabItem.TICKETS_IN_CHARGE_BY_ME)
        //             return it
        //         else if (document['roleTFEXTicketViewAll'] || document['roleTFEXTicketViewGroup'] || this.isAdmin)
        //             return it
        //     }
        //     else if (it.parentType == type)
        //         return it
        // }) as Interfaces.ITabItem[]
        // : []


        return <div className="ticket-tabs__header">
            <div className='ticket-tabs__header__tabs'>
                {tabCorresponding && tabCorresponding.length > 0 &&
                    tabCorresponding.map((it, index) => {
                        return renderTabItem(it, index)
                    })}
                {tab == null &&
                    <div className={`ticket-tabs__header__tabs__item active`} key={Utils.getNewGuid()}>
                        Tùy chỉnh
                    </div>
                }
            </div>
        </div>
    }

    const renderTotalCount = () => {
        return <div style={{ fontSize: '12px' }}>
            {/* {totalCount > 50 && countClickTotal == 0
            ? <HrvComponents.Tooltip title={'Nhấn vào đây để xem tổng số ticket'}>
                <span onClick={() => this.onClickGetTotalCount()}
                    style={{ color: "#2979FF" }}
                >
                    Tìm thấy nhiều hơn 50 kết quả
                </span>
            </HrvComponents.Tooltip>
            : <span>Tìm thấy <span style={{ margin: '0 2px' }}>
                {isLoadingTotalFull
                    ? 0
                    : Math.max(totalCountFull, totalCount)
                }
            </span> kết quả</span>
        } */}
            <span>Tìm thấy <span style={{ margin: '0 2px' }}>
                {isLoadingTotalFull
                    ? 0
                    : Math.max(totalCountFull, totalCount)
                }
            </span> kết quả</span>
        </div>
    }

    return (
        <div style={{ padding: '0 30px' }}>
            {renderBody()}
            {renderTotalCount()}
        </div>
    )
}