import request from '../utils/axiosService'

const URL_CUSTOMER_PAGING = '/customer/paging';
const URL_ADD_CUSTOMER = '/customer/add';
const URL_UPDATE_CUSTOMER = '/customer/update';

export const getCustomerPaging = (params?: any) => {
    return request({
        url: URL_CUSTOMER_PAGING,
        method: 'get',
        params,
    });
};

export const addCustomer = (data?: any) => {
    return request({
        url: URL_ADD_CUSTOMER,
        method: 'post',
        data,
    });
};

export const updateCustomer = (data?: any) => {
    return request({
        url: URL_UPDATE_CUSTOMER,
        method: 'post',
        data,
    });
};

