import React, { useState, useEffect } from 'react';
import './index.css';
import {
  locationIcon,
  acreageIcon,
  icon_shared,
  widthHeigh,
  copyIcon,
  fbIcon,
  carIcon,
  bedRoomIcon,
} from '../../../utils/icon';
import { InfoDiv } from 'components/InfoDiv';
import { getDateShort } from '../../../utils/DateTimeFormatHelper';
import { IMediaFile, IProduct } from 'interfaces';
import { useParams } from 'react-router-dom';
import { getMyProductDetail, getProductDetail, setProductSaved } from 'repositories';
import { Carousel, Image, Modal, Space, message } from 'antd';
import {
  AcreageCategory,
  DEFAULT_PRODUCT_FILTER,
  PriceCategory,
  TypeProducts,
  DirectionTypes,
  billion,
} from 'helpers/config';
import {
  LeftOutlined,
  RightOutlined,
  DownloadOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  SwapOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,

} from '@ant-design/icons';
import * as Utils from '../../../utils/env';
import { WheelNavigator } from '../../../utils/env';
import { useLocation } from 'react-router'
import { createBrowserHistory } from 'history';
import type { Update } from 'history';
import type { Location } from 'react-router-dom';
import { FiShoppingBag, FiEdit, FiPieChart, FiBarChart, FiCreditCard, FiStar, FiShoppingCart, FiHeart } from 'react-icons/fi';
import { EStatusProduct } from 'helpers/enums';
import { RealEstateCard } from './ProductDetailComponent'

enum Unit {
  Million,
  Billion,
}
const units = {
  [Unit.Million]: 'triệu',
  [Unit.Billion]: 'tỷ',
};
const history = createBrowserHistory();

export const ProductDetail = () => {
  const [item, setItem] = useState<IProduct>();
  const [previewUrl, setPreviewUrl] = useState<string>('');
  const [visible, setVisible] = useState<boolean>(false);
  const location = useLocation()
  const [from, setFrom] = useState<Location | undefined>(undefined);
  const [to, setTo] = useState<Location | undefined>(undefined);
  useEffect(() => {
    getProduct();
  }, []);

  useEffect(() => {
    history.listen((update: Update) => {
      if (update.action === 'POP') setTo(update.location);
    });
  }, []);

  useEffect(() => {
    setFrom(location);
  }, [location]);

  const params = useParams();

  const getProduct = async () => {
    const rsp = await getMyProductDetail(params);
    if (rsp && rsp.data) {
      let data = rsp.data;
      setItem(data.data);
    }
  };

  const [image, setImage] = useState(
    'https://static.hara.vn/1/200000008967/5c9ebe0e86194d5abebb57a38ced2853.jpeg'
  );
  const [description, setDescription] = useState(
    'Noteworthy technology acquisitions 2021'
  );
  const [landAreaSquare, setLandAreaSquare] = useState('70m2');
  // const [location, setLocation] = useState('Hồ Chí Minh');
  const [price, setPrice] = useState(7.5);
  const [unit, setUnit] = useState(Unit.Billion);
  const [pricePerSquare, setPricePerSquare] = useState();
  const [saleDate, setSaleDate] = useState(new Date());
  const [infos, setInfos] = useState([
    { icon: acreageIcon(), info: '85m2' },
    { icon: carIcon(), info: 'Ngõ ô tô' },
    { icon: bedRoomIcon(), info: 'Ngõ ô tô' },
    { icon: acreageIcon(), info: '85m2' },
    { icon: carIcon(), info: 'Ngõ ô tô' },
    { icon: bedRoomIcon(), info: 'Ngõ ô tô' },
  ]);

  if (!item) return <></>;

  const PrevArrow = (props) => {
    const { onClick, className, style } = props;
    return (
      // <div className="arrow" onClick={onClick}>
      <div
        className={className}
        style={{
          ...style,
          display: 'flex', // Use flexbox layout
          justifyContent: 'center', // Center horizontally
          alignItems: 'center',
          background: 'rgba(0, 0, 0, 0.3)',
          borderRadius: '50%',
          padding: '18px',
          insetInlineStart: '2vw',
          zIndex: 1,
          fontSize: '15px',
          lineHeight: '1.5715',
          color: 'white',
        }}
        onClick={onClick}
      >
        <LeftOutlined />
      </div>
    );
  };

  // Custom right arrow component
  const NextArrow = (props) => {
    const { onClick, className, style } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: 'flex', // Use flexbox layout
          justifyContent: 'center', // Center horizontally
          alignItems: 'center',
          background: 'rgba(0, 0, 0, 0.3)',
          borderRadius: '50%',
          padding: '18px',
          insetInlineEnd: '2vw',
          fontSize: '15px',
          lineHeight: '1.5715',
          color: 'white',
        }}
        onClick={onClick}
      >
        <RightOutlined />
      </div>
    );
  };

  const onDownload = () => {
    if (!!previewUrl?.length) {
      fetch(previewUrl)
        .then((response) => response.blob())
        .then((blob) => {
          const url = URL.createObjectURL(new Blob([blob]));
          const link = document.createElement('a');
          link.href = url;
          link.download = 'image.png';
          document.body.appendChild(link);
          link.click();
          URL.revokeObjectURL(url);
          link.remove();
        });
    }
  };

  const setProductSave = async (productId) => {
    let requestParam = {
      productId: productId,
    };
    await setProductSaved(requestParam);
    item.isSaved = item.isSaved == true ? false : true;
    setItem({ ...item });
    if (item.isSaved == true)
      message.success('Lưu thành công');
    if (item.isSaved == false)
      message.success('Xóa thành công');
  }

  const handlePreviewAction = (action: 'previous' | 'next') => {
    const currentIndex = item?.produtMediaFiles?.findIndex(
      (_) => _?.url == previewUrl
    );
    if (currentIndex == null || currentIndex === -1)
      return console.log('Could not found index');

    if (action === 'previous') {
      const newIndex =
        (currentIndex - 1 + item?.produtMediaFiles?.length) %
        item?.produtMediaFiles?.length;
      const newUrl = item?.produtMediaFiles[newIndex]?.url;
      if (!!newUrl?.length) {
        setPreviewUrl(newUrl);
        return;
      }
    } else if (action === 'next') {
      // Call the nextItem method
      const newIndex = (currentIndex + 1) % item?.produtMediaFiles?.length;
      const newUrl = item?.produtMediaFiles[newIndex]?.url;
      if (!!newUrl?.length) {
        setPreviewUrl(newUrl);
        return;
      }
    } else {
      // Handle invalid action
      console.log('Invalid action.');
    }
  };

  const renderLandArea = () => {
    if (!item.acreage)
      return <></>
    return <div><div className='d-flex items-center product-acreage'>
      <span className='d-flex'>{acreageIcon()} &nbsp;:</span> &nbsp;
      <span className='product-acreage'>{item.acreage} m² </span>
      <span className='d-flex ml-3'>{widthHeigh()} &nbsp;:</span> &nbsp;
      <span className='product-acreage'>{item.width}x{item.height} m</span>
    </div>

    </div>
  }
  const renderInfo = () => {

    console.log(DirectionTypes);
    return <div>
      <div className='product-price d-flex items-center' >
        {item.directionType != null ? <span className=''>{"Hướng: " + (DirectionTypes.find(a => a.value == item.directionType)?.label)}</span> : <span></span>}
        {/* {item.typeProduct != null ? <span className='ml-3'>{"Loại: " + (TypeProducts.find(a => a.value == item.typeProduct)?.label)}</span> : <span></span>} */}
        {/* {item.property != null ? <span className='ml-3'>{"Hiện trạng: " + item.property}</span> : <span></span>} */}
      </div>
      {item.typeProduct != null ? <div className='product-price d-flex items-center' >
        <span className=''>{"Loại: " + (TypeProducts.find(a => a.value == item.typeProduct)?.label)}</span>
      </div> : ""}
      {item.property != null ? <div className='product-price d-flex items-center' >
        <span className=''>{"Hiện trạng: " + item.property}</span>
      </div> : ""}
      {item.seriNumber != null ? <div className='product-price d-flex items-center' >
        <span className=''>{"Số sổ: " + item.seriNumber}</span>
      </div> : ""}
    </div>
  }
  return <div className='container'> <RealEstateCard product={item}/></div>
  // return (
  //   <div className="product-detail">

  //     <Carousel
  //       arrows
  //       className="carousel-container"

  //     >
  //       {item?.produtMediaFiles?.length
  //         ? item.produtMediaFiles?.map((media: IMediaFile, index: number) => (
  //           <div className="image-wrapper" key={index.toString()} style={{}}>
  //             <img
  //               src={media?.url}
  //               alt="temp"
  //               loading='lazy'
  //               onClick={() => {
  //                 setPreviewUrl(media?.url);
  //                 setVisible(true);
  //               }}
  //             />
  //             {/* <ReviewImage review={'a' + index} imageUrl={media?.url}/> */}
  //             {/* <Image.PreviewGroup> */}
  //             {/* <Image
  //                 key={index.toString()}
  //                 src={media?.url}
  //                 alt="temp"
  //                 onClick={() => console.log('click on masked')}
  //                 preview={{
  //                   toolbarRender: (
  //                     _,
  //                     {
  //                       transform: { scale },
  //                       actions: {
  //                         onFlipY,
  //                         onFlipX,
  //                         onRotateLeft,
  //                         onRotateRight,
  //                         onZoomOut,
  //                         onZoomIn,
  //                       },
  //                     }
  //                   ) => (
  //                     <>
  //                       <div className="ant-image-preview-switch-left">
  //                         <LeftOutlined onClick={() => console.log('left')}/>
  //                       </div>
  //                       <div className="ant-image-preview-switch-right">
  //                         <RightOutlined onClick={() => console.log('right')}/>
  //                       </div>
  //                       <div className="toolbar-wrapper ant-image-preview-operations">
  //                         <div className="ant-image-preview-operations-operation">
  //                           <DownloadOutlined
  //                             onClick={() => onDownload(media)}
  //                           />
  //                         </div>
  //                         <div className="ant-image-preview-operations-operation">
  //                           <SwapOutlined rotate={90} onClick={onFlipY} />
  //                         </div>
  //                         <div className="ant-image-preview-operations-operation">
  //                           <SwapOutlined onClick={onFlipX} />
  //                         </div>
  //                         <div className="ant-image-preview-operations-operation">
  //                           <RotateLeftOutlined onClick={onRotateLeft} />
  //                         </div>
  //                         <div className="ant-image-preview-operations-operation">
  //                           <RotateRightOutlined onClick={onRotateRight} />
  //                         </div>
  //                         <div className="ant-image-preview-operations-operation">
  //                           <ZoomOutOutlined
  //                             disabled={scale === 1}
  //                             onClick={onZoomOut}
  //                           />
  //                         </div>
  //                         <div className="ant-image-preview-operations-operation">
  //                           <ZoomInOutlined
  //                             disabled={scale === 50}
  //                             onClick={onZoomIn}
  //                           />
  //                         </div>
  //                       </div>
  //                     </>
  //                   ),
  //                 }}
  //               /> */}
  //             {/* </Image.PreviewGroup> */}
  //           </div>
  //         ))
  //         : null}
  //     </Carousel>
  //     <div className="content">
  //       <div className='icon-save-product' onClick={(e) => { setProductSave(item.id); }}><FiHeart stroke='black' style={{ width: '30px', height: '30px' }} fill={item.isSaved == true ? 'red' : 'none'}></FiHeart></div>
  //       <div className={item.status == EStatusProduct.SALE_URGENT ? "title_sale_urgent" : item.status == EStatusProduct.SOLD ? "title_sold" : item.status == EStatusProduct.STOP_SALE ? "title_stop_sale" : "title_sale"}>{item.name}</div>
  //       <div className="d-flex justify-content-between align-items-center section">
  //         <div className="price">
  //           {/* <span>
  //             {price} {units[unit]}
  //           </span> */}
  //           <span className='mr-3' style={{ textDecoration: item.priceSale ? 'line-through' : 'unset' }}>{Utils.convertMilionToBilion(item.price, true)}</span>
  //           {Utils.convertToBillion(item.priceSale, true)} <br></br>
  //           <p></p>
  //           {item.isPriceChange == true ? 'Thương lượng' : ''}
  //         </div>
  //         <div className="sale-date">
  //           Ngày đăng: <span>{getDateShort(item.updatedAt, 'DD/MM/YYYY')}</span>
  //         </div>
  //         <div className="sale-date" onClick={e => { navigator.clipboard.writeText(window.location.href); message.success('Copy link thành công'); }}>
  //           {icon_shared(25, 25)}
  //         </div>
  //       </div>
  //       <div className="location mt-2">
  //         <span>{locationIcon()}</span> &nbsp;
  //         <a target='_blank' href={'https://www.google.com/maps/dir/?api=1&destination=' + (item.address?.street + ", " + item.address?.wardName + ", " + item.address?.districtName + ", " + item.address?.cityName)}> {item.address?.street + ", " + item.address?.wardName + ", " + item.address?.districtName + ", " + item.address?.cityName}</a>
  //       </div>
  //       <div className="location mt-2">
  //         {renderLandArea()}
  //         {/* <span>{acreageIcon()}</span> &nbsp;
  //         {item.acreage + ' m²'} */}
  //       </div>
  //       <div className="location mt-2">
  //         {renderInfo()}
  //         {/* <span>{acreageIcon()}</span> &nbsp;
  //         {item.acreage + ' m²'} */}
  //       </div>
  //       <div className="location mt-2">
  //         <span>Phí :</span> &nbsp;
  //         {item.fee ? Utils.convertMilionToBilion(item.fee, true) : item.feePercent ? item.feePercent + "%" : 'Thương lượng'}
  //       </div>
  //       <div className='contact-employee-product'>
  //         <div className="location mt-2">
  //           <span>{'Liên hệ: '}</span> &nbsp;
  //           {item.employee?.name}&nbsp;
  //           <a href={'tel:' + item.employee?.phone}>{item.employee?.phone}</a>
  //         </div>
  //         <div className="location mt-2">
  //           {item.employee?.jobtitleName} - {item.employee?.departmentName}
  //         </div>
  //       </div>

  //       {/* <div className="info row gy-3 no-gutters" style={{ marginTop: '4px' }}>
  //         {infos?.length > 0 &&
  //           infos.map((item) => {
  //             return (
  //               <div className="col-md-4">
  //                 <InfoDiv icon={item.icon} info={item.info} />
  //               </div>
  //             );
  //           })}
  //       </div> */}
  //       <hr />
  //       <div className="description-wrapper">
  //         <div className="d-flex justify-content-between align-items-center">
  //           <div style={{ fontWeight: 500, fontSize: '18px' }}>Mô tả</div>
  //           {/* <div className="d-flex post-news">
  //             <span>Đăng tin</span>
  //             <span>{fbIcon()}</span>
  //           </div> */}
  //         </div>
  //         <hr />
  //         <div
  //           onClick={(e) => {
  //             navigator.clipboard.writeText(item.description);
  //           }}
  //           className="d-flex justify-content-end align-items-center"
  //           style={{ color: '#2962ff', margin: '8px' }}
  //         >
  //           Sao chép {copyIcon()}
  //         </div>
  //         <div style={{ fontSize: '16px', lineHeight: '20px', whiteSpace: "pre-line" }}>
  //           {item.description}
  //         </div>
  //       </div>
  //     </div>
  //     {
  //       !!previewUrl?.length && (
  //         <Image
  //           src={previewUrl}
  //           // width={200}
  //           style={{ display: 'none' }}
  //           alt="temp"
  //           preview={{
  //             visible,
  //             onVisibleChange: (value) => {
  //               if (!value) setPreviewUrl('');
  //               setVisible(value);
  //             },
  //             toolbarRender: (
  //               _,
  //               {
  //                 transform: { scale },
  //                 actions: {
  //                   onFlipY,
  //                   onFlipX,
  //                   onRotateLeft,
  //                   onRotateRight,
  //                   onZoomOut,
  //                   onZoomIn,
  //                 },
  //               }
  //             ) => (
  //               <>
  //                 <div className="ant-image-preview-switch-left">
  //                   <LeftOutlined
  //                     onClick={() => {
  //                       return handlePreviewAction('previous');
  //                     }}
  //                   />
  //                 </div>
  //                 <div className="ant-image-preview-switch-right">
  //                   <RightOutlined
  //                     onClick={() => {
  //                       return handlePreviewAction('next');
  //                     }}
  //                   />
  //                 </div>
  //                 <div className="toolbar-wrapper ant-image-preview-operations">
  //                   <div className="ant-image-preview-operations-operation">
  //                     <DownloadOutlined onClick={() => onDownload()} />
  //                   </div>
  //                   <div className="ant-image-preview-operations-operation">
  //                     <SwapOutlined rotate={90} onClick={onFlipY} />
  //                   </div>
  //                   <div className="ant-image-preview-operations-operation">
  //                     <SwapOutlined onClick={onFlipX} />
  //                   </div>
  //                   <div className="ant-image-preview-operations-operation">
  //                     <RotateLeftOutlined onClick={onRotateLeft} />
  //                   </div>
  //                   <div className="ant-image-preview-operations-operation">
  //                     <RotateRightOutlined onClick={onRotateRight} />
  //                   </div>
  //                   <div className="ant-image-preview-operations-operation">
  //                     <ZoomOutOutlined
  //                       disabled={scale === 1}
  //                       onClick={onZoomOut}
  //                     />
  //                   </div>
  //                   <div className="ant-image-preview-operations-operation">
  //                     <ZoomInOutlined
  //                       disabled={scale === 50}
  //                       onClick={onZoomIn}
  //                     />
  //                   </div>
  //                 </div>
  //               </>
  //             ),
  //           }}
  //         />
  //       )
  //     }
  //   </div >
  // );
};

const ReviewImage = ({ review, imageUrl }) => {
  const [modalVisible, setModalVisible] = useState(false);

  const handleImageClick = () => {
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  return (
    <>
      {/* <Image
        src={imageUrl}
        onClick={handleImageClick}
        preview={{ visible: false }}
        width={200}
      /> */}
      <img src={imageUrl} alt="temp" onClick={() => console.log('haha')} />
      <Modal
        title="Review"
        visible={modalVisible}
        onCancel={closeModal}
        footer={null}
      >
        <p>{review}</p>
        <Image src={imageUrl} width={400} />
      </Modal>
    </>
  );
};
