import request from '../utils/axiosService'

const URL_GET_ALL = '/jobtitle/getall'
const URL_JOBTITLE_PAGING = '/jobtitle/paging'
const URL_ADD_JOBTITLE = '/jobtitle/add'
const URL_UPDATE_JOBTITLE = '/jobtitle/update'

export const getAllJobtitle = (param) => {
    return request({
        url: URL_GET_ALL,
        method: 'get',
        params: param
    })
}

export const getJobtitlePaging = (params?: any) => {
    return request({
        url: URL_JOBTITLE_PAGING,
        method: 'get',
        params,
    })
}

export const addJobtitle = (data?: any) => {
    return request({
        url: URL_ADD_JOBTITLE,
        method: 'post',
        data
    })
}

export const updateJobtitle = (data?: any) => {
    return request({
        url: URL_UPDATE_JOBTITLE,
        method: 'post',
        data: data
    })
}