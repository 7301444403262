import request from '../../utils/axiosService';

const URL_TICKET_PAGING = '/ticket/paging';
const URL_TICKET = '/ticket';
const URL_ADD_TICKET = '/ticket';
const URL_UPDATE_TICKET = '/ticket/update';

export const getTicketPaging = (params?: any) => {
    return request({
        url: URL_TICKET_PAGING,
        method: 'get',
        params,
    });
};

export const getTicketDetail = (params?: any) => {
    return request({
        url: URL_TICKET + `/${params.id}`,
        method: 'get',
        params,
    });
};

export const addTicket = (data?: any) => {
    return request({
        url: URL_ADD_TICKET,
        method: 'post',
        data,
    });
};

export const updateTicket = (data?: any) => {
    return request({
        url: URL_UPDATE_TICKET,
        method: 'post',
        data,
    });
};
