import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import styled from 'styled-components';



interface SearchInputProps {
    placeholder?: string;
    customStyle?: any
    onChange?: (value: string) => void;
}

interface StyledInputProps {
    customStyle?: string;
}

const StyledInput = styled(Input).
    attrs<StyledInputProps>(props => ({
        customStyle: props.customStyle
    })) <StyledInputProps>`
  background-color: #f5f5f5;
  border-radius: 16px;
  padding: 8px 12px;
  border: none;
  ${props => props.customStyle};

  &::placeholder {
    color: #9ca3af;
  }

  .ant-input {
    background-color: transparent;
  }
`;


export const SearchInput = ({ placeholder = 'Search Ticket Id', onChange, customStyle }: SearchInputProps) => {
    return (
        <StyledInput
            prefix={<SearchOutlined style={{ color: '#9ca3af' }} />}
            placeholder={placeholder}
            customStyle={customStyle}
            onChange={(e) => onChange?.(e.target.value)}
        />
    );
};