import { Checkbox, Flex, Radio } from "antd";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import type { CheckboxGroupProps } from 'antd/es/checkbox';
import { EActivityType, ETicketType } from 'interfaces';
import { CloseOutlined } from '@ant-design/icons';
import useTicketStore from 'store/ticketStore';
import './styles.css';

interface TicketFiltersProps {
    onClose: () => void;
    visible?: boolean;
}

interface ITicketFilter {
    isInteracted: boolean;
    isTakeGuest: boolean;
    isNewGuest: boolean;
    activities: string[];
}

export const TicketFilters: React.FC<TicketFiltersProps> = ({ onClose, visible }) => {
    const { setFilter: setStoreFilter, fetchTickets, resetPageAndTotalCount } = useTicketStore();
    const [localFilter, setLocalFilter] = useState<ITicketFilter>({
        isInteracted: true,
        isTakeGuest: false,
        isNewGuest: false,
        activities: []
    });
    const dropdownRef = useRef<HTMLDivElement>(null);

    // Handle click outside and escape key to close
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (visible && dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                onClose();
            }
        };

        const handleEscapeKey = (event: KeyboardEvent) => {
            if (visible && event.key === 'Escape') {
                onClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('keydown', handleEscapeKey);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('keydown', handleEscapeKey);
        };
    }, [onClose, visible]);

    if (!visible) return null;

    const options: CheckboxGroupProps['options'] = [
        { label: 'Tương tác', value: ETicketType.Interact.toString() },
        { label: 'Dắt khách', value: ETicketType.TaskGuest.toString() },
        { label: 'Khác', value: ETicketType.Other.toString() }
    ];

    const handleOnChangeActivities = (value: string[]) => {
        setLocalFilter(prev => ({
            ...prev,
            activities: value
        }));
    };

    const renderInteract = () => {
        const options = [
            { label: 'Tư vấn', value: EActivityType.CustomerConsulting.toString() },
            { label: 'Thương lượng', value: EActivityType.Negotiation.toString() },
            { label: 'Tương tác giảm giá', value: EActivityType.DiscountInteraction.toString() },
        ];

        return (
            <Checkbox.Group
                value={localFilter.activities}
                onChange={handleOnChangeActivities}
                className="flex flex-col gap-3"
            >
                {options.map((option, index) => (
                    <Checkbox key={`interact-${index}`} value={option.value}>
                        {option.label}
                    </Checkbox>
                ))}
            </Checkbox.Group>
        );
    };

    const renderTakeGuest = () => {
        const options = [
            { label: 'Xem mới', value: EActivityType.ShowNew.toString() },
            { label: 'Xem lại', value: EActivityType.Review.toString() },
            { label: 'Thương lượng', value: EActivityType.Negotiation.toString() },
        ];

        return (
            <Checkbox.Group
                value={localFilter.activities}
                onChange={handleOnChangeActivities}
                className="flex flex-col gap-3"
            >
                {options.map((option, index) => (
                    <Checkbox key={`guest-${index}`} value={option.value}>
                        {option.label}
                    </Checkbox>
                ))}
            </Checkbox.Group>
        );
    };

    const renderOrderActivity = () => {
        const options = [
            { label: 'Truyền thông', value: EActivityType.Media.toString() },
            { label: 'Đăng tin', value: EActivityType.PostNewFeed.toString() },
            { label: 'Đi khảo sát', value: EActivityType.Survey.toString() },
            { label: 'Check quy hoạch', value: EActivityType.CheckPlanning.toString() },
        ];

        return (
            <Checkbox.Group
                value={localFilter.activities}
                onChange={handleOnChangeActivities}
                className="flex flex-col gap-3"
            >
                {options.map((option, index) => (
                    <Checkbox key={`other-${index}`} value={option.value}>
                        {option.label}
                    </Checkbox>
                ))}
            </Checkbox.Group>
        );
    };

    const renderRadioGroup = () => {
        const defaultValue = localFilter.isInteracted
            ? ETicketType.Interact.toString()
            : localFilter.isTakeGuest
                ? ETicketType.TaskGuest.toString()
                : ETicketType.Other.toString();

        const handleOnChangeRadio = (value: string) => {
            let newFilter = {
                ...localFilter,
                activities: [],
                isInteracted: false,
                isTakeGuest: false
            };

            switch (value) {
                case ETicketType.Interact.toString():
                    newFilter.isInteracted = true;
                    break;
                case ETicketType.TaskGuest.toString():
                    newFilter.isTakeGuest = true;
                    break;
            }

            setLocalFilter(newFilter);
        };

        return (
            <Flex vertical gap="middle">
                <Radio.Group
                    options={options}
                    value={defaultValue}
                    optionType="button"
                    buttonStyle="solid"
                    className="custom-radio-group"
                    onChange={(e) => handleOnChangeRadio(e.target.value)}
                />
            </Flex>
        );
    };

    const handleChangeNewGuest = (checked: boolean) => {
        setLocalFilter(prev => ({
            ...prev,
            isNewGuest: checked
        }));
    };

    const handleReset = () => {
        setLocalFilter({
            isInteracted: true,
            isTakeGuest: false,
            isNewGuest: false,
            activities: []
        });
        resetPageAndTotalCount();
        fetchTickets(1);
        onClose();
    };

    const handleApply = async () => {
        const ticketFilter = {
            isInteracted: localFilter.isInteracted,
            isTakeGuest: localFilter.isTakeGuest,
            isNewGuest: localFilter.isNewGuest,
            activities: localFilter.activities.map(Number)
        };

        setStoreFilter(ticketFilter);
        resetPageAndTotalCount();
        await fetchTickets(1, ticketFilter);
        onClose();
    };

    // Desktop Dropdown Version
    const DesktopFilter = () => (
        <div
            ref={dropdownRef}
            className="absolute right-0 top-[calc(100%+4px)] w-[480px] bg-white rounded-xl shadow-lg border border-gray-200 hidden md:block z-50"
        >
            <div className="p-4 space-y-4">
                {/* Header with close button */}
                <div className="flex justify-between items-center">
                    <label className="block font-semibold text-lg">Thông tin hoạt động</label>
                    <button
                        onClick={onClose}
                        className="p-2 hover:bg-gray-100 rounded-lg transition-colors"
                    >
                        <CloseOutlined className="text-gray-500" />
                    </button>
                </div>

                {/* Activity Types */}
                <div>
                    {renderRadioGroup()}
                </div>

                {/* New Customer */}
                <div className="h-11 flex items-center gap-3">
                    <Checkbox
                        checked={localFilter.isNewGuest}
                        onChange={(e) => handleChangeNewGuest(e.target.checked)}
                        className="text-base"
                    >
                        Khách mới
                    </Checkbox>
                </div>

                <div className="h-px bg-gray-200" />

                {/* Activity Options */}
                <div className="space-y-2">
                    {localFilter.isInteracted
                        ? renderInteract()
                        : localFilter.isTakeGuest
                            ? renderTakeGuest()
                            : renderOrderActivity()
                    }
                </div>

                {/* Actions */}
                <div className="pt-3 border-t border-gray-200 flex justify-end gap-2">
                    <button
                        onClick={handleReset}
                        className="h-11 px-6 text-base text-gray-700 hover:bg-gray-50 rounded-xl border border-gray-200"
                        style={{
                            height: '40px', fontSize: '16px'
                        }}
                    >
                        Xóa bộ lọc
                    </button>
                    <button
                        onClick={handleApply}
                        className="h-11 px-6 text-base  rounded-xl hover:bg-blue-700"
                        style={{
                            height: '40px',
                            backgroundColor: '#ffba00', fontSize: '16px',
                            color: '#212121'
                        }}
                    >
                        Áp dụng
                    </button>
                </div>
            </div>
        </div>
    );

    // Mobile version
    const MobileFilter = () => (
        <>
            <div
                className="fixed inset-0 bg-black/50 z-40 md:hidden"
                onClick={onClose}
            />
            <div className={`
                fixed inset-x-0 bottom-0 z-50 md:hidden
                bg-white rounded-t-2xl w-full
                transform transition-transform duration-300
                max-h-[85vh] overflow-hidden flex flex-col
                ${visible ? 'translate-y-0' : 'translate-y-full'}
            `}>
                {/* Drag Handle */}
                <div className="h-1.5 w-12 bg-gray-300 rounded-full mx-auto mt-3" />

                {/* Header with close button */}
                <div className="px-4 py-3 flex justify-between items-center">
                    <h2 className="text-base font-semibold">Lọc hoạt động</h2>
                    <button
                        onClick={onClose}
                        className="p-2 hover:bg-gray-100 rounded-lg transition-colors"
                    >
                        <CloseOutlined className="text-gray-500" />
                    </button>
                </div>

                {/* Content */}
                <div className="flex-1 overflow-y-auto">
                    <div className="px-4 pb-4 space-y-4">
                        {/* Activity Types */}
                        <div>
                            <label className="block text-base font-medium mb-3">Thông tin hoạt động</label>
                            {renderRadioGroup()}
                        </div>

                        {/* New Customer */}
                        <div className="flex items-center gap-3">
                            <Checkbox
                                checked={localFilter.isNewGuest}
                                onChange={(e) => handleChangeNewGuest(e.target.checked)}
                                className="text-sm"
                            >
                                Khách mới
                            </Checkbox>
                        </div>

                        {/* Activity Options */}
                        <div className="space-y-2">
                            {localFilter.isInteracted
                                ? renderInteract()
                                : localFilter.isTakeGuest
                                    ? renderTakeGuest()
                                    : renderOrderActivity()
                            }
                        </div>
                    </div>
                </div>

                {/* Actions */}
                <div className="border-t border-gray-100 bg-white px-4 py-3">
                    <div className="flex gap-2">
                        <button
                            onClick={handleReset}
                            className="flex-1 px-3 py-2 rounded-lg text-sm text-gray-700 bg-gray-100"
                        >
                            Xóa bộ lọc
                        </button>
                        <button
                            onClick={handleApply}
                            className="flex-1 px-3 py-2 rounded-lg text-sm bg-blue-600 text-white"
                        >
                            Áp dụng
                        </button>
                    </div>
                </div>
            </div>
        </>
    );

    return (
        <>
            <DesktopFilter />
            <MobileFilter />
        </>
    );
};