import React, { Children, lazy, useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { useStateContext } from './Contexts/ContextProvider';
import { RequireAuth } from './Contexts/RequiredAuth';
import { FiSettings } from 'react-icons/fi';

import { SignIn, Admin, Error, RedirectPage, Products, ProductDetail, ProductsSaved } from './pages'
import { Navbar, Footer, Sidebar, ThemeSettings } from './components';
import "bootstrap/dist/css/bootstrap.min.css";
import './assets/css/utils.css';
import { AuthenticatedRoute } from 'shared/authentication/authentication-route';
import { LayoutPartial } from 'layouts/LayoutPartial';
import NavigationProvider from 'Contexts/NavigationProvider';

const HomePage = lazy(() => import('./pages/HomePage/index'))
const ProductManagement = lazy(() => import('./pages/Products/Management'))
const ProductMap = lazy(() => import('./pages/Products/ListMap'))
const Employees = lazy(() => import('./pages/Employees/Management'))
const Customers = lazy(() => import('./pages/Customer/Management'))
const Departments = lazy(() => import('./pages/Departments/Management'))
const Jobtitles = lazy(() => import('./pages/Jobtitle/Management'))
const MeProfile = lazy(() => import('./pages/Me/Profile'))
const RecProfile = lazy(() => import('./pages/Recruitment/Profile'))
const Tickets = lazy(() => import('./pages/Tickets'))

function App() {
  const { setCurrentColor, setCurrentMode, currentMode, activeMenu, currentColor, themeSettings, setThemeSettings } = useStateContext();

  useEffect(() => {
    const currentThemeColor = localStorage.getItem('colorMode');
    const currentThemeMode = localStorage.getItem('themeMode');
    if (currentThemeColor && currentThemeMode) {
      setCurrentColor(currentThemeColor);
      setCurrentMode(currentThemeMode);
    }
  }, []);

  const routes = () => {
    return <Routes>
      <Route path="/" element={<Navigate to={'/admin/products/home'} replace />} />
      <Route path="/home" element={<HomePage />} />
      <Route path='/admin/sigin' element={<SignIn />} />
      <Route path='/admin/signin-oidc' element={<RedirectPage />} />

      <Route element={<RequireAuth allowedRoles={null} />}>
        <Route path="/admin/products/home" element={<Products key={"admin_products_home"} />} />
        <Route path="/admin/products/home_status" element={<Products key={"admin_products_home_status"} />} />
        <Route path="/admin/products/map" element={(<ProductMap />)} />
        <Route path="/admin/me/products/saved" element={<ProductsSaved />} />
        <Route path="/admin/me/profile" element={<MeProfile />} />
        <Route path="/admin/product/:id" element={(<ProductDetail />)} />

        // Ticket
        <Route path="/admin/tickets" element={(<Tickets />)} />

        <Route path="/admin/products" element={(<Products />)} />
        <Route path="/admin/product-detail" element={(<ProductDetail />)} />
        <Route path="/admin/products/management" element={(<ProductManagement key={"admin_product_manager"} />)} />
        <Route path="/admin/myproducts/management" element={(<ProductManagement key={"my_product_manager"} />)} />
        <Route path="/admin/employees/management" element={(<Employees />)} />
        <Route path="/admin/customer/management" element={(<Customers />)} />
        <Route path="/admin/departments/management" element={(<Departments />)} />
        <Route path="/admin/jobtitles/management" element={(<Jobtitles />)} />
        <Route path="/admin/recruitment/profile" element={(<RecProfile />)} />
        <Route path="*" element={<Navigate to="/404" replace />} />
      </Route>

    </Routes>
  }

  return (
    <BrowserRouter>
      <NavigationProvider>
        <div className='flex'>
          <LayoutPartial>
            {routes()}
          </LayoutPartial>

        </div>
      </NavigationProvider>
    </BrowserRouter>
  )
}

export default App;
